import { mdiCheckCircle, mdiCloseCircle, mdiCogSync, mdiSleep } from "@mdi/js";
import type { RouteLocationRaw } from "vue-router";

export type Player = {
    uuid: string;
    username: string;
}
export type Service = {
    _id: string;
    name: string;
    nicename: string;
    status: string;
    type: string;
    info: {
        owner: {
            uuid: string;
            username: string;
        }
        primary: boolean;
    };
    players: Player[];
}

export const useDashboardStore = defineStore('dashboardStore', {
    state: () => ({
        title: [] as {title: string; to: RouteLocationRaw; disabled: boolean}[],
        drawer: true,
        services: [] as Service[],
        selectService: null as Service | null,
        interval: null as NodeJS.Timeout | null,
    }),
    actions: {
        getStatusIcon(service: Service) {
            switch (service?.status) {
                case 'starting':
                    return mdiCogSync
                case 'online':
                    return mdiCheckCircle
                case 'stopping':
                    return mdiCogSync
                case 'offline':
                    return mdiCloseCircle
                case 'hibernating':
                    return mdiSleep
                case 'hibernated':
                    return mdiSleep
                case 'inactive':
                    return mdiSleep
            }
        },
        getStatusColor(service: Service) {
            switch (service?.status) {
                case 'starting':
                    return 'blue'
                case 'online':
                    return 'green'
                case 'stopping':
                    return 'blue'
                case 'offline':
                    return 'red'
                case 'hibernating':
                    return 'yellow'
                case 'hibernated':
                    return 'yellow'
                case 'inactive':
                    return 'yellow'
            }
        },
        initInterval() {
            if (this.interval !== null) {
                clearInterval(this.interval)
            }
            this.interval = setInterval(() => {
                this.getService()
            }, 5000)
        },
        toggleDrawer() {
            this.drawer = !this.drawer
        },
        async getServices() {
            try {
                const token = useCookie('token')
                const response = await fetch('https://api.mineclub.dk/v1/service', {
                    headers: new Headers({
                        Authorization: `Bearer ${token.value}`,
                    }),
                });
                if (response.status === 200) {
                    const data = await response.json();
                    this.services = data.data;
                } else {
                    this.services = [];
                }
            } catch (error) {
                console.error(error);
            }
        },
        async getService() {
            if (this.selectService?._id === undefined || this.selectService?._id === null) {
                return
            }
            try {
                const token = useCookie('token')
                const response = await fetch('https://api.mineclub.dk/v1/service/' + this.selectService?._id, {
                    headers: new Headers({
                        Authorization: `Bearer ${token.value}`,
                    }),
                });
                if (response.status === 200) {
                    const data = await response.json();
                    this.selectService = data.data;
                } else {
                    this.selectService = null;
                }
            } catch (error) {
                console.error(error);
            }
        },
        setServices(services: []) {
            this.services = services
        },
        setTitle(title: {title: string, to: RouteLocationRaw, disabled: boolean}[]) {
            this.title = title;
        },
        setSelectService(_id: string | string[] | undefined) {
            if (this.selectService?._id === _id) {
                return
            }
            this.selectService = this.services.find((service) => service._id === _id) || null
        }
    },
})  